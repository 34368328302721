/*@font-face {*/
/*    font-family: NotoSansCJKjp-Bold;*/
/*    src: url(/assets/fonts/NotoSansJP-Bold.otf);*/
/*}*/

/*@font-face {*/
/*    font-family: NotoSansCJKjp-Medium;*/
/*    src: url(/assets/fonts/NotoSansJP-Medium.otf);*/
/*}*/

/*@font-face {*/
/*    font-family: tbudgothic-std,sans-serif;*/
/*    font-weight: 400;*/
/*    font-style: normal;*/
/*}*/

label {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
}

.f-bold {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
}

.slider-navigation ul .menu-item .title-menu,
.bla {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
    font-size: 17px;
}

body,
span,
pre {
    font-size: 15px;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 200;
    /*font-style: normal;*/
}

a {
    text-decoration: none !important;
}

.btn-kiroku {
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-image: -webkit-linear-gradient(bottom, #f2f4f7, #fff);
    background-image: linear-gradient(to top, #f2f4f7, #fff);
    min-width: 120px;
    width: 100%;
    height: 36px;
}

.overflow {
    overflow: hidden;
}

.dropdown-kiroku.dropdown-menu {
    padding: 0;
    width: 100%;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
    z-index: 100;
    min-width: 120px;
}

.customize-media.media {
    padding: 16px 4px;
}

.dropdown-item-kiroku {
    overflow-x: auto;
    display: block;
    width: 100%;
    padding: 10px 10px;
    clear: both;
    font-weight: 400;
    color: #354052;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    cursor: pointer;
    border-bottom: solid 1px #d8dadd;
}

.dropdown-kiroku.dropdown-menu.show.top {
    bottom: 100%;
}

.dropdown-item-kiroku:hover {
    background-color: #f1f4f8;
    color: #2ea2f8;
}

.dropdown .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 .3rem;
    left: 124px;
    top: 10px
}

.icon-kiroku {
    color: #a8aab7;
    font-size: 10px;
    position: absolute;
    top: 11px;
    right: 12px;
}

.left {
    float: left;
}

.text-kiroku {
    color: #354052;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.36;
}

.btn.btn-kiroku-primary {
    border-radius: 4px;
    border: solid 1px #1585d8;
    background-image: -webkit-linear-gradient(bottom, #1991eb, #2da1f8);
    background-image: linear-gradient(to top, #1991eb, #2da1f8);
    padding: 5px 16px;
    color: #fff;
}

.btn.btn-kiroku-primary:hover {
    color: #fff;
    cursor: pointer;
}

.btn.btn-kiroku-primary:active {
    color: #fff;
    border: solid 1px #1585d8;
    background-image: -webkit-linear-gradient(bottom, #1991eb, #2da1f8) !important;
    background-image: linear-gradient(to top, #1991eb, #2da1f8) !important;
    cursor: pointer;
}

.btn.btn-kiroku-success {
    border-radius: 4px;
    border: solid 1px #249533;
    background-image: -webkit-linear-gradient(top, #39b54a, #34aa44 98%);
    background-image: linear-gradient(to bottom, #39b54a, #34aa44 98%);
    padding: 5px 16px;
    color: #fff;
}

.btn.btn-kiroku-success:hover {
    color: #fff;
    cursor: pointer;
}

.btn.btn-kiroku-success:active {
    border-radius: 4px;
    border: solid 1px #249533;
    background-image: -webkit-linear-gradient(top, #39b54a, #34aa44 98%) !important;
    background-image: linear-gradient(to bottom, #39b54a, #34aa44 98%) !important;
    color: #fff;
}

.btn.btn-kiroku-warning {
    border-radius: 4px;
    border: solid 1px #f36a19;
    background-image: -webkit-linear-gradient(top, #f7981c, #f76b1c);
    background-image: linear-gradient(to bottom, #f7981c, #f76b1c);
    padding: 5px 16px;
    color: #fff;
}

.btn.btn-kiroku-warning:active {
    border-radius: 4px;
    border: solid 1px #f36a19;
    background-image: -webkit-linear-gradient(top, #f7981c, #f76b1c) !important;
    background-image: linear-gradient(to bottom, #f7981c, #f76b1c) !important;
    color: #fff;
}

.btn.btn-kiroku-warning:hover {
    color: #fff;
    cursor: pointer;
}

.btn.btn-kiroku-danger {
    border-radius: 4px;
    border: solid 1px #db161e;
    background-image: -webkit-linear-gradient(top, #f85359, #dc151d);
    background-image: linear-gradient(to bottom, #f85359, #dc151d);
    padding: 5px 16px;
    color: #fff;
}

.btn.btn-kiroku-danger:active {
    border-radius: 4px;
    border: solid 1px #db161e;
    background-image: -webkit-linear-gradient(top, #f85359, #dc151d);
    background-image: linear-gradient(to bottom, #f85359, #dc151d);
    color: #fff;
}

.btn.btn-kiroku-danger:hover {
    color: #fff;
    cursor: pointer;
}

.btn.btn-kiroku-white {
    border-radius: 4px;
    border: solid 1px #ced0da;
    background-image: -webkit-linear-gradient(bottom, #f2f4f7, #ffffff);
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
    padding: 5px 16px;
    color: #354052;
}

.btn.btn-kiroku-white:active {
    border-radius: 4px;
    border: solid 1px #ced0da;
    background-image: -webkit-linear-gradient(bottom, #f2f4f7, #ffffff);
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
    color: #354052;
}

.btn.btn-kiroku-white:hover {
    color: #354052;
    cursor: pointer;
}

.btn.btn-kiroku-light {
    border-radius: 4px;
    border: solid 1px #ced0da;
    background-image: -webkit-linear-gradient(bottom, #dfe1e5, #ffffff);
    background-image: linear-gradient(to top, #dfe1e5, #ffffff);
    padding: 5px 16px;
    color: #354052;
}

.btn.btn-kiroku-light:hover {
    color: #354052;
    cursor: pointer;
}

.btn.btn-kiroku-transparent {
    border-radius: 4px;
    border: solid 1px transparent;
    padding: 5px 16px;
    color: #354052;
}

.btn.btn-kiroku-transparent:hover {
    color: transparent;
    cursor: pointer;
}

.btn-outline-search {
    /*border-color: #dbe2e7;*/
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #ffffff;

}

.btn-outline-search:not(:disabled):not(.disabled):active {
    color: #000;
    background-color: #fff;
    /*border-color: #6c757d;*/
}

.check-group {
    display: block;
    position: relative;
    padding-left: 10px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.check-group input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.check-group input:disabled ~ .checkmark {
    cursor: not-allowed;
}

input:disabled {
    cursor: not-allowed;
}

button:disabled {
    cursor: not-allowed !important;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #dee2e6;
    border-radius: 4px;
}

.check-group input:checked ~ .checkmark {
    border: solid 1px #27aa11;
    background-image: -webkit-linear-gradient(bottom, #29b311, #57d841);
    background-image: linear-gradient(to top, #29b311, #57d841);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.check-group input:checked ~ .checkmark:after {
    display: block;
}

.check-group .checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 9px;
    border: solid #ffffff;
    border-width: 0 .12em .12em 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.form-control-kiroku {
    display: block;
    padding: 6px 15px;
    border-radius: 4px;
    border: solid 1px #dfe3e9;
    background-color: #ffffff;
    background-clip: padding-box;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
}

.pr-5px {
    padding-right: 5px;
}

.fa.fa-calendar {
    color: #a8aab7;
}

.media {
    display: flex;
    padding: 16px 12px;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear;
}

.media-single {
    align-items: center;
}

.media.media-single .media-body a {
    text-decoration: none !important;
}

.ps-container {
    position: relative;
    touch-action: auto;
    /*overflow: auto;*/
}

.media-list-divided > .media:not(.media-list-header):not(.media-list-footer), .media-list-divided .media-list-body > .media {
    border-bottom: 1px solid rgba(77, 82, 89, 0.07);
}

.media-list > .media:not(.media-list-header):not(.media-list-footer), .media-list .media-list-body > .media {
    margin-bottom: 0;
}

.media-single, .media-center-v {
    align-items: center;
}

.media {
    padding: 16px 12px;
    display: flex;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear;
}

.media-body {
    min-width: 0;
    flex: 1 1;
}

.media-body > * {
    margin-bottom: 0;
}

.media > * {
    margin: 0 8px;
}

small {
    color: #8b95a5;
}

.card-title {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 200;
    /*font-style: normal;*/
    line-height: 1.5;
    margin-bottom: 0;
    padding: 15px 20px;
    border-bottom: 1px solid rgba(77, 82, 89, 0.07);
}

.card {
    border: 0;
    border-radius: 0;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
    -webkit-transition: .5s;
    transition: .5s;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
}

.badge-kiroku {
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    padding: 8px 8px 8px 8px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    text-transform: uppercase;
    font-family: Noto Sans Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 700;
    /*font-style: normal;*/

}

.badge-kiroku.badge-kiroku-default {
    border: solid 1px #ced0da;
    background-color: #fff;
    color: #354052;
}

.badge-kiroku.badge-kiroku-primary {
    border: solid 1px #1991eb;
    background-color: #1991eb;
}

.badge-kiroku.badge-kiroku-success {
    border: solid 1px #36af47;
    background-color: #36af47;
}

.badge-kiroku.badge-kiroku-warning {
    border: solid 1px #f7981c;
    background-color: #f7981c;
}

.badge-kiroku.badge-kiroku-danger {
    border: solid 1px #ed1c24;
    background-color: #ed1c24;
}

.badge-kiroku.badge-kiroku-dark {
    border: solid 1px #516173;
    background-color: #516173;
}

.badge-kiroku.badge-kiroku-light {
    border: solid 1px #dfe1e5;
    background-color: #dfe1e5;
    color: #354052;
}

.badge-kiroku.badge-kiroku-muted {
    border: solid 1px #c2cad4;
    background-color: #c2cad4;
}

.action-customer-surveys .action-icon {
    padding: 0 10px;
}

@media (max-width: 375px) {
    .action-customer-surveys .survey-actions {
        padding: 0 0 0 0 !important;
    }
}

.action-customer-surveys .survey-actions {
    padding: 0 10px 0 0;
}

.action-customer-surveys .dropdown div.dropdown-kiroku.popover:after {
    border-bottom: 9px solid rgb(24, 25, 69);
}

.action-customer-surveys .dropdown div.dropdown-kiroku.popover {
    background-color: #091e42;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.24);
}

.action-customer-surveys .dropdown-item-kiroku {
    background-color: #091e42;
    border-radius: 4px;
    color: #2ea2f8;
}

.action-customer-surveys .dropdown-item-kiroku:hover {
    background-color: #091e42;
    color: #2ea2f8;
}

.action-customer-surveys .dropdown div.dropdown-kiroku.popover .dropdown-item-kiroku {
    color: #ffffff;
}

.dropdown .popover {
    right: -10px;
    top: 7px;
    overflow: visible !important;
    border: 1px solid rgb(229, 230, 231);
    border-radius: 2px;
}

.dropdown div.dropdown-kiroku.popover {
    border-radius: 4px;
    box-shadow: none;
    margin-top: 20px;
    width: 240px;
}

.action-customer-surveys .dropdown div.dropdown-kiroku.popover {
    border-radius: 4px;
    box-shadow: none;
    margin-top: 20px;
    width: 300px;
}

.dropdown div.dropdown-kiroku.popover:before {
    left: auto;
    right: 20px;
    content: '';
    position: absolute;
    top: -11px;
    width: 0;
    height: 10px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 9px solid rgb(229, 230, 231);
}

.dropdown div.dropdown-kiroku.popover:after {
    left: auto;
    right: 20px;
    top: -9px;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-right: 7px solid transparent;
    border-bottom: 9px solid rgb(255, 255, 255);
    border-left: 7px solid transparent;
}

.popover {
    top: 4px;
    left: -243px;
    max-width: 300px;
}

.divider {

}

.default-background-color {
    background-color: #eef3f6;
    min-height: 100vh;
}

.stylish-input-group .input-group-append .btn-outline-secondary {
    border-color: #ccd2da;
}

.width-40 {
    width: 40%;
}

.mt-16 {
    margin-top: 16px;
}

.mt-12 {
    margin-top: 12px;
}

.label-draft {
    width: 54px;
    height: 20px;
    border-radius: 3px;
    background-color: #f7981c;
    color: #ffffff;
}

.ml-6 {
    margin-left: 6px;
}

.mr-6 {
    margin-right: 6px;
}

.mt-8 {
    margin-top: 8px;
}

.ml-12 {
    margin-left: 12px;
}

.mt-17 {
    margin-top: 17px;
}

.mt-25 {
    margin-top: 25px;
}

.ml-8 {
    margin-left: 8px;
}

.mr-12 {
    margin-right: 12px;
}

.mb-11 {
    margin-bottom: 11px;
}

.mt-7 {
    margin-top: 7px;
}

.pt-8 {
    padding-top: 8px;
}

.mt-11 {
    margin-top: 11px;
}

.modal-kiroku {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    overflow: hidden;
    outline: 0;
}

.modal-footer-kiroku {
    justify-content: center;
}

.display-none {
    display: none;
}

.border-modal {
    border-radius: 4px;
}

.kiroku-btn-data {
    padding: 2px 8px;
    border-radius: 16px;
    border: solid 1px rgba(197, 208, 222, 0.9);
    background-color: #fff;
}

.kiroku-btn-data-more {
    cursor: pointer;
    padding: 2px 8px;
    border-radius: 16px;
    border: solid 1px #1991eb;
    background-color: #fff;
    color: #1991eb;
}

.kiroku-btn-data-more:active {
    padding: 2px 8px;
    border-radius: 16px;
    border: solid 1px #007bff;
    background-color: #fff;
    color: #007bff;
}

.spinner {
    position: relative;
    margin: auto;
    background-image: url("https://apptractor.ru/wp-content/uploads/2017/04/spinner-icon-0.gif");
    background-size: cover;
    background-position: center center;
    width: 20px;
    height: 20px;
}

.react-datepicker-wrapper {
    display: block !important;
}

.react-datepicker-wrapper .react-datepicker__input-container {
    display: block !important;
}

.modal-title {
    width: 100%;
}

.btn-icon {
    border-color: #ccd2da;
    border-bottom: 0;
    border-top: 0;
    border-radius: 4px 0 0 4px !important;
    background-color: #ffffff;
}

.action-input {
    position: absolute;
    right: 10px;
    top: 6px;
}

.action-input-icon {
    position: absolute;
    left: 10px;
    top: 6px;
}

/*----------------upload file---------------*/

.wrap-upload {
    position: relative;
    margin: auto;
    display: flex;
}

.form-upload {
    margin: auto;
}

.image-upload {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 300px;
    height: 204px;
    min-width: 300px;
}

.image-upload-default {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 300px;
    height: 190px;
    min-width: 267px;
    border: dashed 1px #09c199;
}

.overlay-upload-file {
    position: relative;
    margin: auto;
    max-width: 300px;
}

.content-upload {
    position: relative;
    margin: auto;
    text-align: center;
}

.content-upload button {
    width: 100%;
    border-radius: 0 !important;
}

.form-upload .overlay-upload-file {
    opacity: .7;
}

.overlay-upload-file-default {
    position: relative;
}

.overlay-upload-file-default i {
    position: absolute;
    /*margin: auto;*/
    top: 65px;
    bottom: 0;
    right: 0;
    left: 0;
}

.overlay-upload-file-default span {
    position: absolute;
    /*margin: auto;*/
    top: 95px;
    bottom: 0;
    right: 0;
    left: 0;
}

.overlay-upload-file-default .fas {
    font-size: 1.6rem;
    padding-right: 5px;
    color: #6c757d;
}

.change-photo {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: #09c199;
    padding-left: 3px;
}

.react-phone-number-input .react-phone-number-input__row .react-phone-number-input__icon img {
    vertical-align: top;
}

.react-phone-number-input__row {
    border: solid 1px #ced4da;
    border-radius: 4px;
    background-color: #ffffff;
    background-clip: padding-box;
}

.react-phone-number-input__icon {
    margin-left: 1rem !important;
    border: 0 !important;
}

.react-phone-number-input input {
    border: none;
    padding: 18px 15px;
}

.react-phone-number-input__country--native {
    margin-right: 0 !important;
}

.content-question .form-control:disabled {
    background-color: white;
}

.content-question .form-area:disabled {
    background-color: white;
}

.dd-wrapper {
    padding: 8px;
}

.menu-dd-wrapper {
    margin-top: 2px;
    border: solid 1px #dfe3e9;
    background-color: #f7f7f7;
}

.menu-dd-wrapper .dropdown-item-kiroku {
    border: none;
}

.fix-icon-align i {
    position: relative;
    margin: auto 0;
}

.fix-icon-align a {
    color: #ffffff !important;
}

#multi-select .css-vj8t7z {
    border: solid 1px #dfe3e9;
    background-clip: padding-box;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.content-pagination {
    border: solid 1px transparent;
    padding: 5px 14px;
    color: #7f8fa4;
}

.total-pagination {
    color: #7f8fa4;
    text-align: right;
}

/*------------------------------------table--------------------------------------*/

#table table {
    width: 100%;
    table-layout: auto;
    background-color: #fff;
    padding-bottom: 12px;
}

#table .tbl-header {
    background-color: rgba(255, 255, 255, 0.3);
}

#table .tbl-header tr {
    border-bottom: 1px solid #dfe2e5;
}

#table .tbl-content {
    overflow-x: auto;
    margin-top: 0;
}

#table .tbl-content tr:hover {
    background: rgb(238, 243, 246);
}

#table .tbl-content tr:nth-child(even) {
    background: rgba(197, 208, 222, 0.1)
}

#table .tbl-content tr:nth-child(even):hover {
    background: rgb(238, 243, 246);
}

#table th {
    padding: 20px 15px;
    text-align: left;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 400;
    /*font-style: normal;*/
    color: #7f8fa4;
    text-transform: uppercase;
}

#table td {
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    color: #091e42;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.on {
    /*box-sizing: border-box;*/
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    width: 100%;
    max-height: 250px;
    /*overflow-x: hidden;*/
    /*overflow-y: auto;*/
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
}

.off {
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 9999;
    width: 100%;
    max-height: 250px;
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
}

.notification {
    padding: 8px 15px 15px 15px;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    font-size: 1em;
    min-height: 60px;
    line-height: 1.2em;
    position: relative;
}

.notification .close-icon {
    display: flex;
    justify-content: flex-end;
    text-align: end;
}

.notification .fa-check-circle {
    font-size: 20px;
    float: left;
    position: relative;
    margin: auto;
}

.card-kiroku-web {
    border-radius: 0;
    border: solid 1px #dfe2e5;
}

.card-title-web {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 0;
    padding-bottom: 0;
}

#web-q .card-kiroku-web {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-bottom: 0;
}

.kiroku-action-survey {
    width: 24px;
    height: 24px;
    border: 2px solid #a8aab7;
    border-radius: 50%;
    color: #a8aab7;
}

.card-title.card-title-web.bg-success {
    background-color: #e7f9f5 !important;
}

.as {
    position: relative;
    margin: auto;
}

.css-12jo7m5 {
    overflow: visible !important;
}

.form-error {
    border: solid 1px #d30f00 !important;
}

#component-question textarea:disabled {
    background-color: #ffffff;
}

.change-status-password-user {
    position: absolute;
    top: 20%;
    right: 8px;
}

#no-results {
    background-color: #ffffff !important;
}

.align-icon {
    vertical-align: baseline;
    padding-left: 10px;
    color: #c7d2e0
}

.title-label-select-team {
    font-weight: bold;
    color: #36af47;
}

.title-label-select-user {
    font-weight: bold;
    color: #17a2b8 !important;
}

.title-label-select-survey {
    font-weight: bold;
    color: #17a2b8;
}

.badge-select-team {
    background-color: #EBECF0;
    border-radius: 2em;
    color: #36af47;
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    min-width: 1px;
    padding: 0.16666666666667em 0.5em;
    text-align: center
}

.badge-select-user {
    background-color: #EBECF0;
    border-radius: 2em;
    color: #17a2b8;
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    min-width: 1px;
    padding: 0.16666666666667em 0.5em;
    text-align: center
}

.badge-select-survey {
    background-color: #EBECF0;
    border-radius: 2em;
    color: #516173;
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    min-width: 1px;
    padding: 0.16666666666667em 0.5em;
    text-align: center
}

.representative-tag:after {
    content: '\A';
    white-space: nowrap;
}

.tags-dropdown {
    padding-top: 5px;
}

.kiroku-data-view-more {
    padding: 2px 8px;
    border-radius: 5px;
    border: solid 1px rgba(197, 208, 222, 0.9);
    height: 27px;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #fff;
}

.btn.btn-kiroku-beta {
    background-color: #ffff;
    background-image: -webkit-linear-gradient(bottom, #ffffff, #ffffff);
    background-image: linear-gradient(to top, #ffffff, #ffffff);
    border: solid 1px #007BFF;
    color: #007BFF;
}

.answer-checkbox-beta .check-group .checkmark::after {
    left: 4px;
    top: 1px;
    width: 9px;
    height: 14px;
    border: solid #ffffff;
    border-width: 0 .12em .12em 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.answer-checkbox-beta .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dee2e6;
    border-radius: 4px;
}

.avatar-user-comment {
    height: 40px;
    width: 40px;
    float: left;
    position: relative;
    margin-right: 10px;
    text-align: center;
    border-radius: 100%;
    background-position: center center;
    background-size: cover;
}
.content-comment .author {
    font-size: 16px;
    font-weight: 500;
    color: #007bff;
}
.content-comment .meta-data .date {
    color: #7f8fa4;
}
.lds-ellipsis-loading {
    display: inline-block;
    position: relative;
    width: 11px;
    height: 11px;
}

.lds-ellipsis-loading div {
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #565656;
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis-loading div:nth-child(1) {
    left: 6px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis-loading div:nth-child(2) {
    left: 6px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis-loading div:nth-child(3) {
    left: 26px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis-loading div:nth-child(4) {
    left: 45px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
}

@-webkit-keyframes lds-ellipsis-loading1 {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes lds-ellipsis-loading1 {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@-webkit-keyframes lds-ellipsis-loading3 {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}

@keyframes lds-ellipsis-loading3 {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}

@-webkit-keyframes lds-ellipsis-loading2 {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(19px, 0);
                transform: translate(19px, 0);
    }
}

@keyframes lds-ellipsis-loading2 {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(19px, 0);
                transform: translate(19px, 0);
    }
}

.page-login {
  background-color: #eff3f6;
  height: 100vh;
}

.box-login {
  position:relative;
  height: 100%;
  width:100%;
}
.language .logo-kirokuai {

}

.language .flag {
  float: right;
  display: flex;
  padding-top: 8px;
}


.language .flag .flag-icon.ja {
  box-shadow: 1px 2px 3px 2px rgba(0,0,0,0.04);
}

.language .flag .flag-icon.en {
  margin-left: 10px;
  box-shadow: 1px 2px 3px 2px rgba(0,0,0,0.04);
}

.activeLang {
  border: solid 1px #09c199;
  border-radius: 3px;
}

.unActive {
  opacity: 0.5;
}

.language .flag .flag-icon {
  background-size: cover;
  background-position: center center;
  width: 32px;
  height: 24px;
}

@media only screen and (max-width: 425px) {
  .tile {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #ffffff;
  }

  .language {
    padding: 1.5rem 20px;
  }

}


@media only screen and (min-width: 426px) {
  .tile {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    border-radius: 2px;
    width: auto;
    max-width: 375px;
    height: 725px;
    overflow: hidden;
  }

  .language {
    padding: 2rem 20px;
    background-color: #eff3f6;
  }

  .card-login {
    height: 567px;
    background-color: #ffffff;
  }

  .heading-support-page {
    padding: 2rem 20px 45px;
    background-color: #eff3f6;
  }

}

.tile-header {
  padding: 0 20px;
}
.tile-header .login-logo {
  padding-top: 14px;
  margin-bottom: 40px;
  letter-spacing: normal;
  font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
  font-weight: 400;
  /*font-style: normal;*/
  color: #091e42;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.tile .tile-body {
  padding: 0 20px;
}

.tile .tile-body .auth-form-header {
  background-color: transparent;
  border: 0;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: none;
}

.tile .tile-body .auth-form-header h1 {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: -.5px;
}

/**
 * Forms
 */
.form-input {
  display: block;
  position: relative;
  margin-bottom: 2rem;
}

input:-webkit-autofill {
  background-color: transparent !important;
}

.form-input > .material-icons ~ input, .form-input > .material-icons ~ .label, .form-input > .material-icons ~ .underline {
  margin-left: 2.25rem;
  width: calc(100% - 2.25rem);
}
.form-input input {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0 0 0.5rem;
  margin-top: 20px;
  outline-style: none;
  width: 100%;
}
.form-input input ~ .label {
  color: #091e42;
  font-weight: 500;
  font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
  /*font-style: normal;*/
  font-size: 14px;
  margin: 0;
  pointer-events: none;
  position: absolute;
  top: 1.5rem;
  left: 0;
  -webkit-transition: top .2s,font .2s;
  transition: top .2s,font .2s;
  z-index: 1;
}
.form-input input ~ .underline {
  background-color: #7f8fa4;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
}
.form-input input:hover ~ .underline, .form-input input:focus ~ .underline {
  background-color: #1991eb;
}
.form-input input:hover ~ .label, .form-input input:focus ~ .label {
  color: #1991eb;
}
.form-input input:focus ~ .underline {
  height: 1px;
}
.form-input input:focus ~ .label, .form-input input:valid ~ .label {
  top: 0;
  font-size: 0.85rem;
}
.form-input input:-webkit-autofill ~ .label {
  top: 0;
  font-size: 0.85rem;
}

/**
 * Buttons
 */
.btn {
  border-radius: 4px;
  display: inline-block;
  padding: 0.5rem 1rem;
}

.btn, [role="button"], [type="button"] {
  touch-action: manipulation;
  cursor: pointer;
}

.btn-login {
  background-color: #1991eb;
  width: 240px;
  height: 44px;
  border-radius: 4px;
  color: white;
}
.btn-login:hover, .btn-login:focus, .btn-login:active {
  background-color: #007bff;
}

.btn-line-login {
  background-color: #ffffff;
  width: 240px;
  height: 44px;
  border-radius: 4px;
  border: solid 1px #dfe3e9;
  color: #091e42;
}
.btn-line-login:hover, .btn-line-login:focus, .btn-line-login:active {
  background-color: #ffffff;
  border: solid 1px  #1991eb;
}

/**
 * Utilities
 */
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.float-right {
  float: right;
}

/**
 * Submit
 */
.submit-container {
  position: relative;
  text-align: center;
  margin-top: 2rem;
}
.submit-container #submit {
  position: relative;
  z-index: 2;
  outline: none;
  opacity: 1;
  -webkit-transition: opacity .1s ease-in, border-radius .1s ease-in, -webkit-transform .1s ease-in;
  transition: opacity .1s ease-in, border-radius .1s ease-in, -webkit-transform .1s ease-in;
  transition: transform .1s ease-in, opacity .1s ease-in, border-radius .1s ease-in;
  transition: transform .1s ease-in, opacity .1s ease-in, border-radius .1s ease-in, -webkit-transform .1s ease-in;
  right: -1px;
}

.label {
    display: inline;
    font-weight: 500;
    padding: .2em 0 .3em;
    font-size: 75%;
    line-height: 1;
    color: #fff;
    text-align: left;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.hr-login {
  position: relative;
  display: block;
  margin-top: 1.5rem;
  color: #7f8fa4;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}

.hr-login:before {
  content: '';
  width: 100%;
  height: 0;
  border-top: 1px solid #dfe3e980;
  vertical-align: middle;
  position: absolute;
  margin-right: 37px;
  top: 50%;
  right: 64%;
}

.hr-login:after {
  content: '';
  width: 100%;
  height: 0;
  border-top: 1px solid #dfe3e980;
  vertical-align: middle;
  margin-left: 32px;
  position: absolute;
  top: 50%;
  right: -74%;
}

.login-line {
  padding: 0 20px;
  text-align: center;
  margin-top: 2rem;
}

.login-tfa {
  padding: 0 20px;
  text-align: left;
  margin-top: 2rem;
}

.line-icon {
  margin-left: 25px;
  margin-right: 16px;
  width: 24px;
  height: 24px;
  background-position: center center;
  background-size: cover;
}

.change-status-password {
  float: right;
  margin-top: 25px;
  margin-left: -25px;
  position: relative;
  color: #bcbec7;
}

.page-support {
  background-color: #eff3f6;
  height: 100vh;
}

.support-page-header{
  padding-top: 10rem !important;
  text-align: center;
  height: 120px;
}

.heading-support-page-form {
  font-size: 40px;
  text-align: center;
  font-weight: 500;
  font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
  /*font-style: normal;*/
  color: #2da1f8;
}

.sub-title-support-page-form {
  text-align: center;
  font-size: 16px;
  font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
  font-weight: 200;
  /*font-style: normal;*/
  color: #7f8fa4;
}

@media only screen and (max-width: 768px) {

    div.DateRangePicker.DateRangePicker_1 {
        width: 100% !important;
    }

    .wrap-print {
        width: 100%;
    }

    .opacity-mobile {
        z-index: 998;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 200vh;
        cursor: pointer;
    }

    .opacity-main .overlay {
        /*display: none;*/
    }

    .sidenav.close-slider {
        left: -260px !important;
    }

    .back-up-bars {
        padding-right: 18px;
    }

    #list {
        display: none !important;
    }

    .modal-preview-file .modal-dialog {
        max-width: 90% !important;
        margin: 1.75rem auto;
    }

    #medium {
        display: none !important;
    }

    #web {
        display: none !important;
    }

    .padding-responsive {
        padding: 0.5rem;
    }

    .card-dashboard {
        height: 96px;
        width: 50%;
    }

    .notification-container {
        position: fixed;
        width: 100%;
    }

    main {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: auto;
        max-width: 375px;
        height: 400px;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    #fix-date-rage .DateRangePicker_picker.DateRangePicker_picker_1.DateRangePicker_picker__directionLeft.DateRangePicker_picker__directionLeft_2 {
        /*width: 90%;*/
        /*overflow-x: auto;*/
    }

    .rotate-buttons{
        bottom: 50px !important;
    }

    .rotatable-image {
        width: 90vw !important;
        height: 90vw !important;
    }
}

@media only screen and (min-width: 1441px) {
    .header-mobile {
        margin-left: 260px;
        -webkit-transition: 0.3s ease-out;
        transition: 0.3s ease-out;
    }

    .header-mobile.opacity-header {
        margin-left: 53px;
        -webkit-transition: 0.3s ease-out;
        transition: 0.3s ease-out;
    }

    .main-container {
        margin-left: 260px;
        -webkit-transition: 0.3s ease-out;
        transition: 0.3s ease-out;
    }

    .main-container.main-collapse {
        margin-left: 53px;
        -webkit-transition: 0.3s ease-out;
        transition: 0.3s ease-out;
    }

    .padding-responsive {
        padding: 1rem;
    }

    #medium {
        display: none !important;
    }

    #mobile {
        display: none !important;
    }

    .open-slide {
        left: 260px
    }

    .sidenav {
        border-right: 1px solid #dfe2e5;
        z-index: 997 !important;
    }

    .header-mobile-left i {
        display: none !important;
    }

    #overlay-main.overlay {
        display: none;
    }

    #main.opacity-main {
        height: 0;
        position: relative;
    }

    .card-dashboard {
        width: 33%;
    }

    .notification-container {
        top: 20px;
        position: fixed;
        width: 60%;
    }

    .notify-web-a {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    main {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: auto;
        max-width: 500px;
        height: 400px;
        display: flex;
        flex-direction: column;
        text-align: center;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1440px) {
    .back-up-bars{
        display: none;
    }
    .main-container {
        margin-left: 260px;
        -webkit-transition: 0.3s ease-out;
        transition: 0.3s ease-out;
    }

    .main-container.main-collapse {
        margin-left: 53px;
        -webkit-transition: 0.3s ease-out;
        transition: 0.3s ease-out;
    }

    .header-mobile {
        margin-left: 260px;
        -webkit-transition: 0.3s ease-out;
        transition: 0.3s ease-out;
    }

    .header-mobile.opacity-header {
        margin-left: 53px;
        -webkit-transition: 0.3s ease-out;
        transition: 0.3s ease-out;
    }

    .overlay {
        /*display: none;*/
    }

    .padding-responsive {
        padding: 1rem;
    }

    #mobile {
        display: none !important;
    }

    #list {
        display: none !important;
    }

    .open-slide {
        left: 260px
    }

    .card-dashboard {
        width: 33%;
    }

    .notification-container {
        top: 20px;
        position: fixed;
        width: 60%;
    }

    .notify-web-a {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .form-search-survey-web {
        min-width: 100px;
        width: 150px;
        padding-left: 8px;
    }

    main {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: auto;
        max-width: 500px;
        height: 400px;
        display: flex;
        flex-direction: column;
        text-align: center;
    }
}


.DayPicker_weekHeader {
    top: 102px !important
}

.DayPicker_focusRegion {
    padding-top: 50px;
}

.modal-preview-file .modal-dialog {
    max-width: 1235px;
    margin: 1.75rem auto;
}

.modal-preview-file .modal-content img {
    width: auto;
    height: 539px;
    max-height: 539px;
}

.modal-preview-file .modal-dialog.modal-dialog-centered
{
    height: 100%;
}
.modal-preview-file .modal-content.pdf {
    height: 90%;
}
.modal-preview-file .modal-content.png {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    border: 0;
    max-height: 539px;
    max-width: 1235px;
}

.modal-preview-file .modal-content iframe {
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {

    .DayPicker_weekHeader {
        top: 120px !important
    }

    .DayPicker_focusRegion {
        padding-top: 60px;
    }

    .modal-preview-file .modal-content img {
        width: 100%;
        height: auto;
        max-height: 594px;
    }

    .modal-preview-file .modal-dialog {
        width: 100%;
        margin: 1.75rem auto;
    }

    .close-modal-preview.png {
        top: -50px !important;
        right: 0 !important;
    }
}

.close-modal-preview.pdf {
    position: absolute;
    top: -50px;
    right: 0;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.close-modal-preview.png {
    position: absolute;
    top: -50px;
    right: 0;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

@media (min-width: 768px) {

    .menu-link:hover {
        background-color: #cde7fd !important;
        border-left: 2px solid #1991eb !important;
    }

    .menu-link:hover > * {
        color: #1991eb;
    }

    .wrap-print {
        width: 50%;
    }

    .modal-survey .modal-dialog {
        width: 70% !important;
        max-width: 70%;
    }
}

.close-modal-preview:hover,
.close-modal-preview:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.modal-preview-file {
    position: relative;
}

@media (min-width: 375px) {
    .tags-list .dropdown-kiroku.dropdown-menu.headerm {
        min-width: 138px;
    }

}

@media (max-width: 850px) {
    .text-page-size {
        display: none;
    }
}

.scroll-data-logic-single {
    overflow-y: auto;
    max-height: 60vh;
}

.group-btn{
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 99;
}

.rotatable-image {
    width: 90vh;
    height: 90vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

@media only screen and ( max-width: 850px) {
    .hidden-username-on-header {
        display: none;
    }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.2;
    color: inherit;
}

.default-background-color {
    background-color: #eef3f6;
    min-height: 100vh;
}

.main-content-mobile {
    padding-top: 70px;
}

.header-mobile {
    position: fixed;
    display: flex;
    height: 70px;
    max-height: 70px;
    padding: 0 18px;
    top: 0;
    right: 0;
    left: 0;
    z-index: 995;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    box-shadow: 4px 0 5px rgba(0, 0, 0, 0.08);
}

.title-page-component {
    font-size: 18px;
    color: #091e42;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
}

.header-mobile-left {
    display: flex;
    align-items: center;
}

.sidenav.close-slider .divclass:hover .fa.fa-bars:before {
    content: "\F061";
    cursor: pointer;
}

.sidenav.close-slider .title-menu {
    display: none !important;
}

.sidenav.close-slider .menu-link {
    display: flex;
    flex-direction: row-reverse;
}

.sidenav.close-slider .menu-item.active .menu-link {
    border-right: 2px solid #1991eb !important;
}

.sidenav.open-slide .divclass:hover .fa.fa-bars:before {
    content: "\F060";
    cursor: pointer;
}

.divclass {
    font-size: 20px;
}

.fa.fa-bars {
    color: #1991eb;
    font-size: 20px;
    cursor: pointer;
}

.header-mobile-right {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.header-user-avatar {
    margin-top: 0;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: row-reverse;
}

.header-user-avatar .avatar {
    cursor: pointer;
    z-index: 100;
    position: relative;
    margin-left: 12px;
    width: 36px;
    height: 36px;
    text-align: center;
    border-radius: 100%;
    background-position: center center;
    background-size: cover;
}

.header-notify {
    display: inline-block;
}

.ant-badge {
    position: relative;
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
}

.sup-count {
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: -3px;
    height: 15px;
    border-radius: 10px;
    min-width: 15px;
    background: #f04134;
    color: #fff;
    line-height: 15px;
    text-align: center;
    padding: 0 2px;
    font-size: 12px;
    white-space: nowrap;
    -webkit-transform-origin: -10% center;
            transform-origin: -10% center;
}

.notify {
    font-size: 35px;
    color: #7f8fa4;
    padding-top: 3px;
    padding-right: 10px;
}

.topbar-divider {
    border-left: 1px solid rgba(77, 82, 89, 0.07);
    height: 20px;
    align-self: center;
    margin: 0 5px;
}

.dropdown-kiroku.dropdown-menu.headerm {
    right: 5px;
    top: 62px;
    width: auto;
    overflow: visible !important;
    border: 1px solid rgb(229, 230, 231);
    border-radius: 2px;
}

.dropdown-kiroku.dropdown-menu.headerm:before {
    left: auto;
    right: 21px;
    content: '';
    position: absolute;
    top: -11px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 9px solid rgba(0, 0, 0, 0.07);
}

.tags-list .dropdown-kiroku.dropdown-menu.headerm:before {
    left: 10px;
}

.tags-list .dropdown-kiroku.dropdown-menu.headerm:after {
    left: 10px;
}

.representative-tag {
    display: inline-block;
}

/*.tags-list span{*/
/*    font-size: 12px;*/
/*}*/

/*.certificate-list span {*/
/*    font-size: 12px;*/
/*}*/

.dropdown-kiroku.dropdown-menu.headerm:after {
    left: auto;
    right: 21px;
    top: -9px;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-right: 7px solid transparent;
    border-bottom: 9px solid rgb(255, 255, 255);
    border-left: 7px solid transparent;
}

.dropdown-kiroku.dropdown-menu.headerm .dropdown-item {
    color: #4d5259;
    font-size: 14px;
}

.dropdown-kiroku.dropdown-menu.headerm .dropdown-item:hover {
    background: #f5f5fa;
}

.wraper-avatar {
    display: flex;
    color: #7f8fa4;
}

.item-user-profile {
    padding: 10px 26px;
}

.fs-16px {
    font-size: 16px;
}

.dropdown-item > * {
    color: #7f8fa4;
}

.avatar-line-login {
    position: relative;
    margin-top: 2px;
    width: 24px;
    height: 24px;
    background: url("/assets/img/line-me.png") center center;;
    background-size: cover;
}

.sidenav {
    height: 100%;
    width: 260px;
    position: fixed;
    z-index: 999;
    top: 0;
    left: -207px;
    background-color: #fff;
    border-right: 1px solid #dfe2e5;
    overflow-x: hidden;
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
}

.open-slide {
    left: 0;
}

.display-none {
    display: none;
}

.display-none-important {
    display: none !important;
}

.opacity-main {
    z-index: 998;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 200vh;
    cursor: pointer;
}

.overlay {
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-color: black;

}

.opacity-header {
    z-index: 995 !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: #fff;
}

.opacity-image {
    opacity: 0.3;
}

.slider-close {
    position: fixed;
    top: 8px;
    color: #ffffff;
    right: 23px;
    font-size: 24px;
}

.header-slidenav {
    height: 70px;
    background-color: #fff;
    padding: 0 12px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dfe2e5;
}

.slider-navigation {
    position: relative;
    overflow: hidden;
    flex: 1 1;
    touch-action: auto;
}

.menu {
    list-style: none;
    padding-left: 0;
    margin-bottom: 30px;
}

.menu-category {
    position: relative;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    padding: 1rem 20px 0.5rem;
    color: #7f8fa4;
    opacity: 0.7;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu-category:after {
    content: '';
    width: 100%;
    height: 0;
    border-top: 1px dashed #7f8fa4;
    vertical-align: middle;
    margin-left: 1.5rem;
    opacity: 0.3;
    position: absolute;
    top: 50%;
    margin-top: 3px;
}

.menu-item {
    vertical-align: top;
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
    cursor: pointer;
}

a.menu-item {
    text-decoration: none !important;
}

.menu-item .menu-link {
    color: #7f8fa4;
    height: 56px;
    padding: 0 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    -webkit-transition: .2s linear;
    transition: .2s linear;
}

.menu-item.active .menu-link {
    background-color: #cde7fd !important;
    border-left: 2px solid #1991eb !important;
}

.menu-item.active .menu-link > * {
    color: #1991eb !important;
}

.menu-item .menu-link .title-menu {
    flex-grow: 1;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    line-height: 1;
}

.menu-item .menu-link > * {
    margin-left: 8px;
    margin-right: 8px;
}

.logo-kirokuai {
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1991eb;
}

.content-checkbox {
    margin-top: -4px;
    margin-left: 10px;
    margin-bottom: 10px;
    font-family: Noto Sans JP, sans-serif;
    /*font-style: normal;*/
    font-weight: normal;
}

.form-area {
    padding: 6px 15px;
    border-radius: 4px;
    border: solid 1px #d8d9dc;
    min-height: 90px;
    width: 100%;
    resize: none;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #27aa11;
}

.custom-radio .custom-control-input:disabled ~ .custom-control-label::before,
.custom-radio .custom-control-input:disabled ~ .custom-control-label::after
{
    cursor: not-allowed;
}

.setFontSpan {
    font-family: Noto Sans JP,sans-serif;
    font-weight: 400;
    /*font-style: normal;*/
}

.view-1vh {
    height: 100vh;
    background-color: #ffffff
}

.filter-by-web {
    background-color: transparent;
    border-color: transparent;
    padding: 0;
}

#web-q .bg-card-web {
    background-color: #e8ecf1 !important;
}

.overlay-action-creator {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000000;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.action-creator {
    z-index: 5;
    position: absolute;
    top: 30%;
    right: 11%;
    bottom: 0;
}

.scroll-data-dropdown-search {
    max-height: 178px;
    overflow-y: auto;
}

.pt-edit {
    padding-top: 0.12rem !important;
}

#fix-date-rage {
    border-radius: 4px;
    height: 36px;
}

#fix-date-rage .DateRangePickerInput_arrow {
    position: relative;
    margin: auto 0;
}

#fix-date-rage .DateRangePickerInput__withBorder {
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    /*height: 36px;*/
    /*width: 286px;*/
    display: flex;
    max-width: 100% !important;
}

.exports #fix-date-rage .DateRangePickerInput__withBorder {
    width: 286px;
}

.date-time-range #fix-date-rage .DateRangePickerInput__withBorder {
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    height: 36px !important;
    /*width: 286px;*/
    display: flex;
    max-width: 100% !important;
}

.date-time-range #fix-date-rage .DateInput_input.DateInput_input_1 {
    padding: 0.3rem 0 0.3rem 1rem;
    border-bottom: 1px solid #dbdbdb !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
    font-size: 14px;
}

#fix-date-rage .DateInput.DateInput_1 {
    height: 35px;
}

#fix-date-rage .DateInput_input.DateInput_input_1 {
    padding: 0.3rem 0 0.3rem 1rem;
    /*border-bottom: 1px solid #dbdbdb;*/
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
    font-size: 14px;
}


#fix-date-rage .DateRangePicker.DateRangePicker_1 #endDate {
    border-bottom-left-radius: 0;
}

#fix-date-rage .DateRangePicker.DateRangePicker_1 #startDate {
    border-bottom-right-radius: 0;
}

#fix-date-rage input:focus {
    border-bottom: 2px solid #1991eb !important;
}

#fix-date-rage .CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #1991eb;
    border: 1px double #1991eb;
    color: #fff;
}

#fix-date-rage .DateRangePickerInput_clearDates,
#fix-date-rage .DateRangePickerInput_clearDates:hover {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 6px 8px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.fix-icon-input-search {
    border: 0;
    background: transparent;
}

.fa.fa-search {
    font-size: 16px;
}

#input-form-searcher button:focus,
#input-form-searcher button:hover,
#input-form-searcher button:active {
    outline: none;
    border: none;
}

.react-phone-number-input__country-select {
    pointer-events: none;
}

.react-phone-number-input__country.react-phone-number-input__country--native {
    display: none;
}


.scrollbar {
    height: 200px;
    overflow-y: auto;
}

.force-overflow {
    min-height: 200px;
}


#style-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

#style-scroll::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

#style-scroll::-webkit-scrollbar-thumb {
    background-color: #6c798f;
}

.main-loading .fade.show {
    background: #2c3135d4;
}

.close-modal-preview.pdf {
    position: absolute;
    top: -50px;
    right: 10px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.close-modal-preview.png {
    position: absolute;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.close-modal-preview:hover,
.close-modal-preview:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.modal-preview-file {
    position: relative;
}


.modal-preview-file .modal-dialog {
    margin: 1.75rem auto;
}

.modal-preview-file .modal-kiroku.fade.show {
    overflow: scroll;
}

.modal-preview-file .modal-dialog.modal-dialog-centered {
    height: 100%;
}

.modal-preview-file .modal-content.pdf {
    height: 90%;
}

.modal-preview-file .modal-content.png {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    border: 0;
}

.modal-preview-file .modal-content iframe {
    width: 100%;
    height: 100%;
}

.modal-survey .card {
    border-radius: 4px;
    border: 0;
}

.modal-survey .sumary-content2 {
    width: 100%;
}

@media only screen and (min-width: 1400px) {
    .modal-survey .content-check {
        width: 85%;
    }
}

@media only screen and (max-width: 1399px) {
    .modal-survey .content-check {
        width: 100%;
    }
}

.padding-top-2px {
    padding-top: 2px;
}

.scroll_Answer_Question {
    overflow: auto;
    height: 360px;
}

.btn-action-review .btn {
    padding: 3px 13px;
}

.wrap-next-question button {
    background-image: -webkit-linear-gradient(bottom, #dfe1e5, #ffffff);
    background-image: linear-gradient(to top, #dfe1e5, #ffffff);
    padding: 5px 16px;
    color: #354052;
    border: solid 1px #ced0da;
}

.tranfer-text,
.tranfer-text:hover,
.tranfer-text:active {
    color: transparent;
}

.submit-search {
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    width: 38px;
    background: #17a2b8;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
}

.submit-search i {
    padding-left: 12px;
    color: #fff;
    cursor: pointer;
}

.text--a {
    font-size: 85%;
    font-weight: bold;
}

.scroll-history-answer {
    max-height: 582px;
    overflow-y: auto;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-disable {
    cursor: not-allowed !important;
}

input:disabled {
    cursor: not-allowed !important;
}

textarea:disabled {
    cursor: not-allowed !important;
}

.custom-control-disable {
    cursor: not-allowed !important;
}

.group-pin:hover .fas.fa-star {
    color: #f7981c !important;
}

.group-pin:hover .far.fa-star {
    color: #f7981c !important;
}

.bg-disable-question {
    background-color: #6f747b !important;
}

.d-flex-inline {
    display: inline;
}

.read-only-date > input, .serviced-at > input {
    background-color: white !important;
}

.text-page-size {
    color: #7f8fa4;
}

.dropdown-page-size {
    min-width: 60px;
}

.survey-list .page-size-custom .dropdown-kiroku.dropdown-menu.show {
    min-width: 70px;
    height: 181px !important;
}

.print-modal {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.border-service {
    border: 2px solid #dfe3e8;
    border-radius: 4px;
}

.shortcut-wrapper {
    position: absolute;
    top: 1rem;
    left: 1rem;
}

.shortcut-content {
    color: #007bff;
    cursor: pointer;
}

.shortcut-content:hover:active {
    color: #0362c7;
}

.btn.btn-switch-1 {
    width: 50%;
    border-radius: 4px 0 0 4px !important;
}

.btn.btn-switch-2 {
    width: 50%;
    border-radius: 0 4px 4px 0 !important;
}

.label-status-record-list {
    border-radius: 12px;
    width: 80px;
    text-align: center;
}

.label-date-time-picker {
    border-radius: 12px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}

.label-date-time-picker.active {
    background-color: rgb(25, 145, 235);
    color: rgb(255, 255, 255);
}

.background-color {
    background-color: #ffffff !important;
}

.icon-color-black i {
    padding-left: 12px;
    color: #e0e3e9;
    cursor: pointer;
}
.highLightText {
    background-color: #ffd640

}
.icon-order .fa-lg.fa-sort-up {
    vertical-align: bottom;
}
.icon-order .fa-lg.fa-sort-down {
    vertical-align: top;
}
.color-blue {
   color: #007bff !important;
}

.wrap-text-customs-date {
    flex-wrap: wrap;
}

.font-size-20 {
    font-size: 20px;
}
.border-status-searching {
    border: 1px solid rgb(25, 145, 235);
}

.react-tooltip-lite {
    background: #333;
    color: white;
}

.react-tooltip-lite-arrow {
    border-color: #333;
}

.text-overflow-elip {
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.height-text-next-question {
    height: 24px;
}
.css-1q79lcu {
    font-weight: normal !important;
}
.rdtSwitch, .dow {
    font-weight: 500;
}

.text-black {
    color: #0a1d42;
}

.date-time-range-report #fix-date-rage .DateRangePickerInput_clearDates_default_2 {
    line-height: 9px;
}

.date-time-range-report #fix-date-rage .DateRangePickerInput__withBorder {
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    height: 38px !important;
    /*width: 286px;*/
    display: flex;
    max-width: 100% !important;
}

.kiroku-support {
    text-align: center;
    height: 50px;
    color: #007BFF;
}

.input-code {
    border: solid 0;
    border-bottom: solid 1px #dfe3e9;
    width: 75px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid #C8CCD4;
    outline: none;
    padding: 1px 1px 1px 3px;
    /*transition: border-bottom 2s;*/
    text-align: center
}

.input-code:focus {
    border: solid 0;
    border-bottom: solid 2px #007bff;
    width: 75px;
    box-shadow: none;
}

.btn-connect-disabled {
    border-radius: 4px;
    width: 200px;
    background-color: #ffffff;
    border: solid 1px #DFE3E8;
    color: #DFE3E8;
    height: 36px;
    line-height: 16px;
}

.btn-connect-active {
    border-radius: 4px;
    width: 200px;
    background-color: #4291E4;
    border: solid 1px #4291E4;
    color: #FFF;
    height: 36px;
    line-height: 16px;
}

.btn-connect-active:active {
    border-radius: 4px;
    background-color: #4291E4;
    color: #FFF;
    line-height: 16px;
}

.padding-location-support-page {
    padding: 90px 1px 1px 1px;
}

.margin-line-back:before {
    margin-right: -27px;
}

.margin-line-back:after {
    margin-right: 70px;
}

.text-question-confirm-addr-header {
    font-size: 16px
}

.text-question-confirm-addr {
    color: #7f8fa4;
    font-size: 14px;
    padding-top: 10px;
}

.btn-cancel-confirm-overwrite-addr {
    border: solid 1px #1891eb;
}

.border-btn-paginator {
    height: 38px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border: solid 1px #007BFF;
    color: #007BFF
}

button.border-btn-paginator:disabled{
    height: 38px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border: 1px solid #DFE3E8;
    color: #DFE3E8;
    background-color: #FFFFFF !important;
}


.btn-back {
    margin-right: 10px;
    padding: 0 9px 2px 8px;
}

.btn-next {
    margin-left: 10px;
    padding: 0 9px 2px 8px;
}

.btn-action-active {
    width: 100%;
    font-size: 16px;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    background-color: #2B91FF;
    color: #ffffff;
}

.btn-action-deActive {
    width: 100%;
    font-size: 16px;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    color: #2B91FF !important;
    background-color: #ffffff;
}
.btn-del {
    border-radius: 5px 0 0 5px !important;
    border-color: #2B91FF;
}
.btn-cre {
    border-radius: 0 5px 5px 0 !important;
    border-color: #2B91FF;
}

.font-text-deleted-list {
    color: #7F8FA4 !important;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 200;
    /*font-style: normal;*/
}

.font-text-header-deleted-list {
    color: #4290E1 !important;
    font-size: 16px;
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    font-weight: 400;
    /*font-style: normal;*/
}
.width-table-create-del-at {
    width: 300px;
}

.circle-up-btn .fa-chevron-circle-up{
    color: #007BFF;
}

.font-username {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 20px;
    color: #212529
}

.color-btn-paginator .border-btn-paginator{
    color : #2B91FF;
}

.scroll-header-on-mobile {
    z-index: 995 !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: #fff;
}

.header-scroll-mobile {
    display: flex;
    height: 70px;
    max-height: 70px;
    padding: 0 18px;
    top: 0;
    right: 0;
    left: 0;
    z-index: 995;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    box-shadow: 4px 0 5px rgba(0, 0, 0, 0.08);
}
.text-decoration {
    text-decoration: line-through;
}

.word-break {
    word-break: break-all
}
.text-404 {
    font-size: 179px;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: bold;
    color: #e0e0e0;
    line-height: 170px;
}

.text-page-not-found {
    font-size: 35px;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 700;
    font-style: normal;
}

.pt-5px {
    padding-top: 5px;
}

.pt-4px {
    padding-top: 4px;
}

.unrequited-checkbox > div > .content-checkbox{
    margin-top: -2px;
}

.keeping-about-survey {
    position : -webkit-sticky;
    position : sticky;
    z-index: 997;
}
.text-link {
    color: #007BFF;
    cursor: pointer;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
}

.rotate-buttons{
    position: fixed;
    bottom: 50px;
    display:none;
}

.padding-reset-button-link {
    padding: 0 8px
}
.label-answer-analysis {
    margin-left: 47px;
}

.title-table-del {
    font-size: 18px;
}

.title-table-del-name {
    font-size: 18px;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 300;
    /*font-style: normal;*/
}

.header-table-del {
    font-size: 14px;
    color: #757575 !important;
}

.font-size-16 {
    font-size: 16px;
}

.hr-table-mobile {
    color : #F2F3F3;
    size: 1px;
    margin-bottom: 0;
}
.title-card-del {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 14px;
    color : #202528;
}

.header-card-del {
    color: #007bff;
    font-size: 18px;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-style: normal;*/
    font-weight: 300;
}

.card-body-del {
    width: 100%;
}

.card-media-screen {
    width: 100%
}
.create-del-at {
    color: black;
}

.font-size-condition {
    width: 50%;
}

.width-full {
    width: 100%;
}

.font-size-condition input {
    font-size: 16px;
}

.font-size-condition ::-webkit-input-placeholder {
    color: #7f8fa4;
}

.font-size-condition ::-ms-input-placeholder {
    color: #7f8fa4;
}

.font-size-condition ::placeholder {
    color: #7f8fa4;
}

.icon-kiroku-white {
    color: #a8aab7;
    font-size: 10px;
    position: absolute;
    top: 13px;
    right: 12px;
}
.btn-kiroku-white-del {
    border-radius: 4px;
    border: solid 1px #E3E6EA !important;
    min-width: 120px;
    width: 100%;
    height: 38px;
    background-image: none !important;
    background-color: #FFFFFF !important;
}
.color-text-drop {
    color: #7f8fa4;
}
.color-blue-icon-order{
    color: #4290E1;
}

tr.border-bottom > td{
    border-bottom: 1px solid #E5E9EC !important;
}

tr.border-bottom-none > td {
    border-bottom: none !important;
}

.deleted-list-th{
    padding: 10px 15px !important;
}

#deleteWebPage #table .tbl-content tr:nth-child(even) {
    background: rgb(255, 255, 255) !important;
}

#deleteWebPage #table .tbl-content tr:nth-child(even):hover {
    background: rgba(197, 208, 222, 0.3) !important;
}

#deleteWebPage #table .tbl-header tr {
    border-bottom: 1px solid #E5E9EC !important;
}

.field-date-time-del {
    color: #202528 !important;
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
}

.pd-top-10{
    padding-top: 10px;
}

.date-time-item{
    display: inline-block;
    text-align: right;
}

.year-of-action{
        width: 75px;
}

.month-of-action{
    width: 47px;
}

.date-of-action{
    width: 47px;
}

.hour-of-action{
    width: 35px;
}

.minute-of-action{
    width: 20px;
}

background-question.record-editor .description {
    height: 144px;
    border-radius: 4px;
    border: solid 1px #dfe2e5;
    background-color: #e9edf1;
}
#create-record .background-question {
    background-color: #e7f9f5;
    width: 100%;
    padding: 8px;
}

#create-record .content-heading-question,
#create-record .content-name-question {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 400;
    /*font-style: normal;*/
    font-size: 16px;
    color: #0a1d42;
    line-height: 1.63;
    padding-top: 8px;
    display: block;
}

#create-record .question-number {
    font-size: 16px;
    color: #0a1d42;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
}



.background-question-review {
    width: 100%;
    padding: 8px;
}

.info-question {
    border-radius: 4px;
    border: solid 1px #dfe2e5;
    background-color: #e9edf1;
    padding: 12px;
}

.survey {
    /*background-color: #eef3f6;*/
}

.survey .status-survey {
    background-color: #eef3f6;
    height: 68px;
    padding: 16px 8px;
}

.survey .status-survey button {
    padding: 5px 18px;
}

@media only screen and (max-height: 400px) {
    .action-record {
        display: none !important;
    }
}

.action-record {
    position: fixed;
    padding: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 998;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    box-shadow: 4px 0 5px rgba(0,0,0,0.08);
}

.survey .action-record button {
    border-radius: 0;
    width: 50%;
    padding: 8px 16px;
}

.survey .action-record button:disabled.btn.btn-kiroku-primary {
    border-radius: 0;
    width: 50%;
    text-transform: none;
    background-color: #dfe1e5 !important;
    opacity: .3;
    padding: 8px 16px;
}

.text-tranf {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-weight: 700;*/
    /*font-style: normal;*/
    font-size: 14px;
    line-height: 1.5;
}

.status-survey-content {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
    color: #6c798f;
}

.yes-check {
    padding:0 0.5rem 0.5rem 0.5rem;
    background-image: -webkit-linear-gradient(bottom, #f2f4f7, #ffffff);
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
    border: solid 1px #ced0da;
    border-bottom: 0;
}

.no-check {
    padding:0 0.5rem 0.5rem 0.5rem;
    background-image: -webkit-linear-gradient(bottom, #f2f4f7, #ffffff);
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
    border: solid 1px #ced0da;
}

.answer-question .custom-control-label::before {
    border: solid 1px #cecdda;
}

.answer-question>textarea:active,
.answer-question>textarea:focus {
    outline: none !important;
    border:1px solid #cecdda;
    box-shadow: 0 0 10px #cecdda;
}

#component-question .number-question {
    margin-bottom: 8px;
}

#component-question .answer-question {
    padding: 8px;
}

#component-question .background-question {
    background-color: #e7f9f5;
    width: 100%;
    padding: 8px;
}

#component-question .wraper-question {
    /*min-height: 100vh;*/
    /*overflow-y: scroll;*/
}

#component-question .content-heading-question,
#component-question .content-name-question {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 400;
    /*font-style: normal;*/
    font-size: 16px;
    color: #0a1d42;
    line-height: 1.63;
    padding-top: 8px;
    display: block;
}

#component-question .question-number {
    font-size: 16px;
    color: #0a1d42;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
}

#component-question .padding-question-content {
    padding: 8px 8px 0 8px
}

#component-question .width-reset-question-link {
    width: 75px
}


/*----------------------------------------Riview css--------------------------------------------*/

#question-review .content-question {
    padding: 8px;
}

#question-review .number-question {
    background-color: #e9edf1;
    padding: 8px;
}

#question-review .answer-question {
    padding: 8px;
}

#question-review .background-question {

}

#question-review .wraper-question {
    border-radius: 4px;
    border: solid 1px rgba(197, 208, 222, 0.9);
    background-color: #ffffff;
}

#question-review .content-heading-question,
#question-review .content-name-question {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 400;
    /*font-style: normal;*/
    font-size: 16px;
    color: #0a1d42;
    line-height: 1.63;
    padding: 8px;
    display: block;
}

#question-review .question-number {
    font-size: 16px;
    color: #0a1d42;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
}

#question-review .image-upload {
    min-width: 285px;
}


#create-record .title-record-info {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 15px;
    color: #091e42;
}

.filter-modal .scroll-data-customer {
    overflow-y: scroll;
    max-height: 274px;
    padding:  0;
    background-color: #fff;
}
#create-record-fix .question-number {
    font-size: 17px;
    color: #0a1d42;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
}

#create-record-fix .image-upload {
    min-width: 285px;
}


#create-record-fix .title-record-info {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 15px;
    color: #091e42;
}

.filter-modal .scroll-data-customer .dropdown-item-kiroku {
    border-bottom: 1px solid rgba(77, 82, 89, 0.07) !important;
}

.filter-record {
    font-size: 16px;
    color: #2c3135;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: normal;
    /*font-style: normal;*/
}

.action-record-modal {
    padding-left: 40px;
    padding-right: 50px;
    position: fixed;
    bottom: 24px;
    width: 100%;
}

#edit-record .popover.dropdown-kiroku.dropdown-menu {
    width: 50px;
    min-width: 14rem;
}

.customer-name {
    color : #7f8fa4
}

.action-record .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0;
}

#about-survey .background-question-review {
    padding: 0;
}

span#wave {
    position: relative;
}
.dot {
    display:inline-block;
    width:8px;
    height:8px;
    border-radius:50%;
    margin-right:3px;
    background:#303131;
    -webkit-animation: wave 1.3s linear infinite;
            animation: wave 1.3s linear infinite;
}

.dot:nth-child(2) {
     -webkit-animation-delay: -1.1s;
             animation-delay: -1.1s;
 }

.dot:nth-child(3) {
     -webkit-animation-delay: -0.9s;
             animation-delay: -0.9s;
 }

@-webkit-keyframes wave {
    0%, 60%, 100% {
        -webkit-transform: none;
                transform: none;
        -webkit-transform: initial;
                transform: initial;
    }

    30% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }
}

@keyframes wave {
    0%, 60%, 100% {
        -webkit-transform: none;
                transform: none;
        -webkit-transform: initial;
                transform: initial;
    }

    30% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }
}

.font-text-question-has-answer {
    color: #3c763d;
    font-size: 16px;
    font-weight: 500;
}

.bg-question-has-answer {
    background-color: #dff0d8 !important;
    color: #3c763d;
    border-color: #d6e9c6;
}

.bg-question-required-answer {
    background-color: #f2dede;
    border-color: #ebccd1;
}

.text-question-required-answer {
    color: #a94442 !important;
    font-size: 16px;
    font-weight: 500;
}

.card-dashboard {
    border-radius: 3px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 6px 19px 0 rgba(197, 197, 197, 0.24);
    background-color: #ffffff;
}

.title-card-dashboard {
    font-size: 14px;
    color: #091e42;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 400;
    /*font-style: normal;*/
}

.number-card-dashboard {
    font-size: 32px;
    color: #091e42;
}

.recent-title {
    font-size: 24px;
    color: #091e42;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 300;
    /*font-style: normal;*/
}

.disabled-link {
    cursor: not-allowed;
    opacity: 0.4;
}

.disabled-link > a {
    pointer-events: none;
}

.customer-list {
    padding-top: 8px;
    background-color: #eef3f6;
}

.customer-list .d-flex .dropdown .btn {
    padding: .473rem .75rem;
}

.customer-list .btn.btn-kiroku-primary {
    padding: 5px 22px;
}

.customer-list .form-group input {
    width: 100%;
}

.customer-list .pr-10 {
    padding: 7px 5px;
}

.customer-list .form-group {
    margin-top: 16px !important;
    margin-right: 0.5rem;
    margin-left: 0.5rem;

}

.customer-list .btn-kiroku {
    min-width: 115px;
}

.customer-list .dropdown-kiroku.dropdown-menu {
    min-width: 115px;
}

.bg-customer-editor {
    background-color: #eef3f6;
}

.customer-list .bulk-action {
    width: 132px;
    height: 36px;
}

.customer-profile .personal-info {
    color: #6c798f;
    font-size: 14px;
}

.action-customer .action-icon {
    padding: 0 10px;
}

.action-customer .dropdown div.dropdown-kiroku.popover:after {
    border-bottom: 9px solid rgb(24, 25, 69);
}

.action-customer .dropdown div.dropdown-kiroku.popover {
    background-color: #091e42;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.24);
}

.action-customer .dropdown div.dropdown-kiroku.popover .dropdown-item-kiroku {
    color: #ffffff;
}

/*----------------------------------------Customer-detail css--------------------------------------------*/

.pt-10 {
    padding-top: 10px;
}

.pl-10 {
    padding-left: 10px;
}

.pr-10 {
    padding-right: 10px;
}

.pt-8 {
    padding-top: 8px;
}

.pl-12 {
    padding-left: 12px;
}

.mt-10 {
    margin-top: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mt-16 {
    margin-top: 16px;
}

.pt-16 {
    padding-top: 16px;
}

.ml-20 {
    margin-left: 20px;
}

.customer-detail .card-customer-detail {
    border-radius: 1px;
    box-shadow: 1px 0 0 0 #dfe2e5;
    border: solid 1px #dfe2e5;
    border-left: 0;
    border-right: 0;
    padding-top: 24px;
    padding-left: 12px;
    padding-right: 12px;
}

.customer-detail .customer-info {
    color: #091e42;
    font-size: 14px;
}

.customer-detail .margin-tab {
    margin: 0 12px;
}

.customer-detail .personal-info {
    color: #6c798f;
    font-size: 14px;
}

.customer-detail .avatar-staff {
    position: relative;
    display: inline-block;
    margin-right: 8px;
    width: 16px;
    height: 16px;
    text-align: center;
    border-radius: 100%;
    background-position: center center;
    background-size: cover;
    vertical-align: sub;
}

.customer-detail .content-avatar {
    margin-top: 16px;
    color: #7f8fa4
}

.customer-detail .height-avatar {
    height: 98px;
    width: 98px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 50%;
}

.customer-detail .btn-change-avatar {
    width: 146px;
    height: 36px;
    border-radius: 4px;
    border: solid 1px #1585d8;
    background-color: #39b54a;
}

@media only screen and (max-width: 325px) {
    .customer-detail .nav-bar {
        font-size: 12px;
        font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
        font-weight: 500;
        /*font-style: normal;*/
        border: solid 1px transparent;
        padding: 7px 0;
        color: #6c798f;
    }

}

@media only screen and (min-width: 326px) {
    .customer-detail .nav-bar {
        font-size: 13px;
        font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
        font-weight: 500;
        /*font-style: normal;*/
        border: solid 1px transparent;
        padding: 7px 0;
        color: #6c798f;
    }
}

.customer-detail .nav-bar.active {
    border-bottom: 2px solid #1991eb !important;
    color: #1991eb;
}

.customer-detail .dropdown-kiroku.dropdown-menu.headerm {
    top: 32px;
}

.main-content-mobile .modal-body {
    padding: 0;
}

.main-content-mobile #body-modal-as .card .media-list.media-list-hover.media-list-divided.scrollable.ps-container.ps-theme-default > div {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    height: 40px;
    background-image: -webkit-linear-gradient(bottom, #f2f4f7, #ffffff);
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
    padding: 8px 1rem;
}

.customer-detail .scroll-data-customer,
#body-modal-as .scroll-data-customer,
#assigh-survey .scroll-data-customer {
    overflow-y: scroll;
    height: 221px;
    background-color: #f8f9fa;
}

/*----------------------------------------Customer-editor css--------------------------------------------*/

.pt-10 {
    padding-top: 10px;
}

.pl-10 {
    padding-left: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-24 {
    margin-top: 24px;
}

.ml-20 {
    margin-left: 20px;
}

.customer-detail .card-customer-editor {
    border-radius: 1px;
    box-shadow: 1px 0 0 0 #dfe2e5;
    border: solid 1px #dfe2e5;
    padding-top: 24px;
    padding-bottom: 24px;
}

.customer-detail .content-avatar {
    margin-top: 16px;
    color: #7f8fa4
}

.customer-detail .height-avatar {
    height: 98px;
}

.customer-detail .btn-change-avatar {
    width: 146px;
    height: 36px;
    border-radius: 4px;
    border: solid 1px #1585d8;
    background-color: #39b54a;
}

#card-customer .action-customer-surveys .dropdown div.dropdown-kiroku.popover {
    width: 220px;
}

@media only screen and (max-width: 449px) {
    .group-action-customer-create-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .m-20-mb {
        margin-top: 1rem;
    }

    .w-100-mb {
        width: 100%;
    }

    .group-action-customer-create-mobile.mt-24.pb-5 button {
        width: 100%;
        padding: 7px 16px;
    }

    .center-xs {
        text-align: center;
    }
}

@media only screen and (min-width: 450px) {
    .group-action-customer-create-mobile {
        justify-content: center !important;
        -webkit-box-pack: center !important;
        display: flex !important;
    }

    .m-20-mb {
        margin-left: 20px;
    }
}

.wrap-upload-customer {
    border-radius: 4px;
    border: dashed 2px #b9bbbd;
    -webkit-transition: .5s;
    transition: .5s;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
}


.wrap-upload-customer.active {
    border-radius: 4px;
    border: dashed 2px #1991eb;
    -webkit-transition: .5s;
    transition: .5s;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
}

.form-upload-data {
    position: relative;
}

.insert-form {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.border-icon-upload {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    padding: 19px;
    background-color: #cde7fd;
}
.border-icon-upload-previews {
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    padding: 19px;
    background-color: #e5e5e5;
}

.info-file {
    word-wrap: break-word;
}

.info-file-mobile {
    max-width: 250px;
    word-wrap: break-word;
    text-align: center;
}

.upload-item__progress-bar {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background-color: #bdbdbd;
}

.upload-item__progress-bar-status {
    width: 100%;
    height: 100%;
    background-color: #1991eb;
}

.border-icon-upload.active {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    padding: 19px;
    background-color: #cde7fd;
    border: 2px solid #1585d8;
}

.color-icon-upload-customer {
    color: #7f8fa4;
    font-size: 2.5rem;
}

.text-upload-customer {
    padding-top: 10px;
    text-align: center;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
    color: #7f8fa4;
}

.files-views ul {
    list-style: none;
}

.color-action {
    color: #1991eb;
}

#modal-assign .customize-media.media{
    display: none;
}

.create-header {
    background-color: #e7f9f5;
}


.scroll-data-survey {
    overflow-y: scroll;
    height: 221px;
    background-color: #f8f9fa;
}

.list-modal-type {
    padding: 10px 20px;
    box-shadow: inset 0 -1px 0 0 #dfe3e9;
}

.teams-image {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 150px;
    height: 150px;
}
@media only screen and (max-width: 325px) {
    .staff-detail .nav-bar {
        font-size: 12px;
        font-weight: 500;
        font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
        /*font-style: normal;*/
        border: solid 1px transparent;
        padding: 7px 0;
        color: #6c798f;
    }

}


@media only screen and (min-width: 326px) {
    .staff-detail .nav-bar {
        font-size: 13px;
        font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
        font-weight: 500;
        /*font-style: normal;*/
        border: solid 1px transparent;
        padding: 7px 0;
        color: #6c798f;
    }
}

.staff-detail .nav-bar.active {
    border-bottom: 2px solid #1991eb !important;
    color: #1991eb;
}

.staff-detail .avatar-user-detail {
    width: 120px;
    height: 120px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 100%;
}

.staff-detail .height-avatar {
    height: 98px;
}

.date-log {
    color: #6c798f;
}

.avartar-log {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-size: cover;
    background-position: center center
}
.sumary-content {
    width: 50%;
}

.sumary-content2 {
    width: 60%;
}

.content-check {
    width: 80%;
}

.action-fix {
    position: relative;
    margin: auto;
}

.action-fix-plus .kiroku-action-survey {
   border-color: #1991eb;
}

.action-fix-plus i,
.action-fix-plus span {
    color: #1991eb;
}

.fix-center {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.wrap-overlay-action {
    position: relative;
    width: 100%;
    height: 100%;
}

.overlay-action {
    -webkit-transition: .5s ease;
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    right: -110px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.card-body .wrap-overlay-action {
    opacity: 0.3;
}

.card-body:active .wrap-overlay-action {
    opacity: 1;
}

.position-survey {
    background-color: transparent;
    padding: 10px 5px;
    border: solid 1px transparent;
    cursor: pointer;
}

button:disabled.position-survey {
    color: #6c757d7d;
}
.card-body:hover .overlay-action {
    opacity: 1;
}

.font-text-header-card {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
}
.group-select-type-question-on-survey {
    width: 100%;
}


.page-error {
    height: 100%;
    z-index: 9999999;
    width: 100%;
    position: absolute;
    overflow: hidden;
}

.wrapper-page-error {
    position: relative;
    width: 100%;
    height: 100%;
}

.policy-page {
    padding: 2cm 2cm 2cm 3cm;
}

.policy-page p {
    text-align: justify;
    text-indent: 30px;
    word-break: break-all;
    font-size: 14px;
}
.policy-page .number-list {
    counter-reset: list;
    padding: 0 0 0 5px;
}
.policy-page .number-list li {
    list-style: none;
    text-align: justify;
}
.policy-page .number-list li:before {
    content: "(" counter(list) ")";
    counter-increment: list;
    text-align: justify;
}

.table-privacy {
    padding: 0 50px 0 50px
}

.table-privacy table tr td {
    word-break: break-all;
}


@media only screen and (max-width: 700px) {
    .policy-page {
        padding: 2cm 1cm 2cm 1cm !important;
    }

    .table-privacy {
        padding: 0 !important;
    }

}
.A4 {
    background: #FFFFFF;
    width: 21cm;
    display: block;
    padding: 10px 25px;
    margin: 0 auto 0.5cm;
    /*box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);*/
    box-sizing: border-box;
    font-family: "MS PGothic", sans-serif;
    font-size: 12px;
}

.policy-page div {
    font-size: 14px;
}

.policy-page ol li {
    font-size: 14px;
}

.policy-page ul li {
    font-size: 14px;
}

.text-align {
    text-align: justify;
}

.font-weight-header {
    font-weight: 700
}

.align-header {
    text-align: center;
}

.width-table-privacy {
    width: 50%
}
.align-content {
    text-align: justify;
    text-indent: 39px;
    word-break: break-all;
}
.font-size-content {
    font-size: 14px;

}

.card-aly {
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background: #fff;
}

.bg-title-aly {
    background-color: #e9edf1;
    padding: 8px;
}

.title-aly {
    font-size: 16px;
    color: #0a1d42;
    font-family: Noto Sans JP , NotoSansCJKjp-Bold,sans-serif;
    font-weight: 700;
    font-style: normal;
}

.text-title {
    font-family: Noto Sans JP , NotoSansCJKjp-Regular,sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    color: #091e42;
}

@media screen and (min-width: 992px){
    .border-analysis {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.dot-icon {
    font-size: 12px;
    color: rgba(197, 208, 222, 0.9);
}

.list-style-analysis {
    list-style: none;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
}

.list-style-analysis-2 {
    list-style: none;
    -webkit-padding-start: 1.2rem;
            padding-inline-start: 1.2rem;
    border-left: dashed 1px #dfe2e5;
    margin-left: 5px;
}

.list-li {
    line-height: 2.3;
}

.text-list-day {
    color: #7f8fa4;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
    font-size: 5px;
}

.react-calendar-heatmap text {
    font-size: 5px;
    fill: #aaa;
}

.react-calendar-heatmap .color-empty { fill : #ebedf0}

.react-calendar-heatmap .color-null { fill : #ebedf0}

.react-calendar-heatmap .color-scale-less { fill: #58CCED; }

.react-calendar-heatmap .color-scale-normal { fill: #4d80e4; }

.react-calendar-heatmap .color-scale-normal { fill: #3895D3; }

.react-calendar-heatmap .color-scale-full-fill { fill: #1261A0; }

.react-calendar-heatmap .react-calendar-heatmap-weekday-labels {
    -webkit-transform: translate(10px, 10px);
            transform: translate(10px, 10px);
}

.react-calendar-heatmap .react-calendar-heatmap-month-labels {
    -webkit-transform: translate(40px, 0px);
            transform: translate(40px, 0px);
}

.date-chart-search .form-control {
    background-color: #fff;
}

@media only screen and (max-width: 768px) {
    .chart-smaller {
        max-width: 50% !important;
    }

    .chart-larger {
        max-width: 100% !important;
    }

}

.report-tab {

}

.report-tab .wrap-content {
    /*padding-top: 80px;*/
    padding-bottom: 80px;
}

.report-tab .wrap-content .A4 {
    width: 21cm;
    /*border: solid 1px #DDDD;*/
    /*box-shadow: 0 0 0.1cm rgba(0, 0, 0, 0.5);*/
    background: #FFFFFF;
    display: block;
    padding: 10px 25px;
    margin: 20px auto 0.5cm;
    box-sizing: border-box;
    font-size: 14px;
}

.report-tab .wrap-content .A4 .content {

}

.report-tab .wrap-content .A4 .content .wrap-bg {
    background-color: #FFF;
}

.striped-list .striped-item:nth-child(even) {
    background-color: #fcfcfc
}

label.report-tab {
    color : #7f8fa4;
    font-weight: 400;
    margin-bottom: 0;
    font-size: 14px;
}

img.report-tab {
    width: 100%;
    height: auto;
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
}

.border-title-template {
    border-bottom: 5px solid #212529;
    font-size: 16px;
}

.border-customer-name {
    border-bottom: 2px solid #212529;
    font-size: 16px;
}

.report-list {
    border-bottom: 1px solid #707070 ;
    padding-top: 10px;
}

.answers-list .answer-item:nth-child(even) {
    background-color: rgba(197, 208, 222, .1);
}

.answer-content {
    border: none;
    font-size: 16px;
    width: 100%;
}

textarea.answer-content {
    height: 30px; resize: none;
}

.end-answer {
    border-bottom: 1px solid #707070;
    font-size: 16px;
}

.answer {
    /*background-image: linear-gradient(to right, #cccccc 33%, rgba(255,255,255,0) 0%);*/
    /*background-position: bottom;*/
    /*background-size: 20px 1px;*/
    /*background-repeat: repeat-x;*/
    border-bottom: 1px dashed #DDDDDD;
    font-size: 16px;
}

.question {
    border-bottom: 1px solid #707070 ;
}

.border-bottom-dashed {
    border-bottom: 1px dashed #DDDDDD;
}

.border-bottom-inline {
    border-bottom: solid 1px #7f8fa4;
}

.pb-10px {
    padding-bottom: 10px;
}

.pt-10px {
    padding-top: 10px;
}

.fix-margin-row {
    margin: 0;
}

.fix-padding-col {
    padding-left: 0;
}


.border-btn-status-group {
    height: 44px;
    border-radius: 5px;
}

.border-btn-status-active {
    border: 1px solid #2B91FF;
    height: 44px;
    border-radius: 5px;
    color: #007BFF
}


.btn-active {
    background-color: #2B91FF!important;
    color: #ffffff !important;
}

.border-width-each-status {
    border: 1px solid #2B91FF;
    height: 44px;
    width: 100%;
    text-align: center;
    padding-top: 9px;
    background-color: #ffffff;
    color: #007BFF
}

.border-width-each-status-center {
    border-top: 1px solid #2B91FF;
    border-bottom: 1px solid #2B91FF;
    height: 44px;
    width: 100%;
    text-align: center;
    padding-top: 9px;
    background-color: #ffffff;
    color: #007BFF
}

.first-btn {
    border-radius: 5px 0 0 5px;
}

.third-btn {
    border-radius: 0 5px 5px 0;
}

.text-span-selected {
    padding-top: 8px;
    font-size: 18px;
    color: #757575;
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
}
.col-md-4-custom {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.font-customer-name {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-weight: 700;*/
    /*font-style: normal;*/
    font-size: 18px;
    color : #007BFF
}

.font-time-records {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 14px;
    color: #202528;
}

.font-highlight-keyword{
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 14px;
    color: #7F8FA4;
}

.btn-group-select {
    padding-left: 15px;
}



@media only screen and (max-width: 841px) {
    .btn-group-select {
        /*display: none;*/
    }
}

@media only screen and (min-width: 800px) {
    .btn-group-select {
        display: flex!important;
    }
    .pt-30px-important {
        padding-top: 30px !important;
    }
}

@media only screen and (min-width: 1132px) {
    .col-md-4-custom {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .pt-30px-important {
        padding-top: 37px !important;
    }
}

@media only screen and (max-width: 1132px) {
    .col-md-4-custom {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
.font-size-18 {
    font-size: 18px;
}


.group-batch-action {
    font-size: 16px;
    color: #007BFF !important;
}

.checkbox-weight > div > .check-group > .checkmark {
    width: 31px;
    height: 31px;
    background-color: #fcfcfc;
    border: 1px solid #707070;

}

.checkbox-weight > div > .check-group .checkmark:after {
    left: 9px;
    font-size: 29px;
    top: 0;
    width: 13px;
    height: 23px;
}

.th-date-batch-action-record-beta {
    color: #757575;
    font-size: 14px;
    cursor: pointer;
}

.checkbox-bigger > div > .check-group > .checkmark {
    width: 31px;
    height: 31px;
}

.checkbox-bigger > div > .check-group .checkmark:after {
    left: 9px;
    font-size: 29px;
    top: 0;
    width: 13px;
    height: 23px;
}
.checkbox-bigger > div > .content-checkbox {
    margin-left: 19px;
    margin-top: 2px;
    margin-bottom: 0;
    font-size: 18px;
}

.border-bottom {
    border-bottom: 1px solid #F2F3F3;
}

.font-customer-name .label-record-beta .font-label-draft {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 14px;
}

.border-back-btn {
    border: 1px solid #007BFF;
    height: 44px;
    border-radius: 5px;
    background-color: #ffffff;
    width: 88px;
}

.font-back-label {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 16px;
    color: #2B91FF;
}

.font-customer-name-detail {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-style: normal;*/
    font-size: 20px;
    /*font-weight: 500;*/
    color : #007BFF;
}

.navbar-tab-records {
    border-bottom: 1px solid rgb(223, 227, 233);
    background-color: rgb(255, 255, 255);
}

.records-detail .nav-bar.active {
    border-bottom: 2px solid #4291E4 !important;
    color: #4592E4;
    width: 90px;
}

.records-detail {
    text-align: center;
}


.none-border-question {
    border: none !important;
}

.content-question-type {
    padding: 1px 1px 8px 1px;
}

.font-heading-questions {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-style: normal;*/
    color: #0E1D3F;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 1px 1px 16px;
}

.font-content-question {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 16px;
    color: #6E798D;
}

.text-form-area {
    font-size: 18px;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    color: #0E1D3F;
}
.modal-select-record-beta > div > .modal-kiroku > .modal-dialog {
    max-width: 400px;
    align-items: normal;
}

.padding-content-question {
    padding: 0 0 0 16px;
}

.answer-question.size-select-question .custom-control-label::after,
.answer-question.size-select-question .custom-control-label::before{
    width: 1.25rem;
    height: 1.25rem;
}

.padding-16-answer-question {
    padding-left: 16px !important;
}

.padding-responsive-detail-record {
    padding-left: 20% ;
}

@media only screen and (max-width: 375px) {
    .padding-responsive-detail-record {
        padding-left: 9% ;
    }
}

@media only screen and (max-width: 320px) {
    .padding-responsive-detail-record {
        padding-left: 4% ;
    }
}

.font-text-in-checkbox {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 18px;
    padding-left: 8px;
    color: #202528;
}

.font-text-reset {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 16px;
}

.font-text-on-btn {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 16px;
}
.nav-bar .badge {
    position: absolute;
    border-radius: 50%;
    background-color: #707070;
    color: white;
}

.fixed-footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 900;
    overflow: hidden;
    outline: 0;
}
#padding-checkbox-card-list .customize-media.media.media-single{
    padding: 4px 0 4px 0;
}
.pb-5-customer-selected {
    padding-bottom: 5rem!important;
}
.kiroku-section-drop-down-show-more-scroll > div > div >.css-10nd86i > .css-2o5izw > .css-1hwfws3{
    max-height: 200px;
    overflow: scroll;
}
.fix-icon-search > form > div > .action-input {
    top: 9px
}

.font-text-not-found {
    font-size: 18px;
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    color: #6E798D;
    text-align: center;
}

.fix-height-date-range > .date-time-range #fix-date-rage .DateRangePicker {
    width: 100%;
}
.fix-height-date-range > .date-time-range #fix-date-rage .DateInput_input.DateInput_input_1 {
    border-bottom: none !important;
    padding: 8px 0 0 1rem;
}
.fix-height-date-range > .date-time-range #fix-date-rage .DateRangePickerInput__withBorder {
    height: 42px !important;
    justify-content: space-evenly;
}

.placeholder-beta-color-757575 > form > div > ::-webkit-input-placeholder {
    color: #757575;
}

.placeholder-beta-color-757575 > form > div > ::-ms-input-placeholder {
    color: #757575;
}

.placeholder-beta-color-757575 > form > div > ::placeholder {
    color: #757575;
}

.label-drafted-at .badge-kiroku {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    font-weight: 200;
    /*font-style: normal;*/
    font-size: 14px;
}

.font-text-records-table {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 20px;
}

.font-text-date-time-records-table {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 20px;
    color: #202528 !important;
}

.font-reset-user-changed {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 16px;
}
.align-self-center {
    font-size: 18px;
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    color: #ffffff;
}

.btn-cancel-on-modal {
    font-size: 18px;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-weight: 700;*/
    /*font-style: normal;*/
    color: #309CF7;
    background-color: #ffffff;
    border-radius: 4px ;
    border: solid 1px #309cf7;
    min-width: 124px;
}

.btn-confirm-on-modal {
    font-size: 18px;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    color: #ffffff;
    background-color: #309CF7;
    min-width: 124px;
}

.font-header-on-modal {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
    font-size: 20px;
    color: #0E1D3F;
}
.set-height-42 #multi-select > div > .css-10nd86i > .css-vj8t7z{
    min-height: 42px;
}
.set-height-42 #multi-select > div > .css-10nd86i > .css-2o5izw {
    min-height: 42px;
}

.color-dropdown-select-pagination {
    color: #757575;
}
.label-record-beta .label-name{
    font-size: 18px !important;
    margin: 0;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif !important;
    /*font-weight: 500 !important;*/
    /*font-style: normal;*/
}

.background-highText-record-beta {
    background-color: #f4ff3e;
}

.css-input-medium {
    font-size: 16px;
    height: 42px;
}
.font-name-san {
    font-size: 16px;
}

.label-name {
    font-size: 18px;
}
.font-customer-name-detail .font-name-san {
    font-size: 20px !important;
    /*font-weight: 500 !important;*/
    margin: 0;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-style: normal;*/
    color: #007bff;
}
.font-customer-name-detail .label-name {
    font-size: 20px !important;
    /*font-weight: 500 !important;*/
    margin: 0;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-style: normal;*/
    color: #007bff;
}

.content-checkbox-beta {
    font-size: 18px;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    color: #0E1D3F
}

.title-date-time {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-weight: 700;*/
    /*font-style: normal;*/
    font-size: 18px;
}

.custom-clear-date #fix-date-rage .DateRangePickerInput_clearDates{
    padding-top: 3px;
}
.batch-action {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    margin-bottom: 10px;
}

.text-customer-selected {
    padding: 8px 0 0 0;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-weight: 700;*/
    /*font-style: normal;*/
    font-size: 18px;
    color : #757575
}
.btn-deActive {
    border: 1px solid #007BFF;
    color: #007bff;
    background-color: #FFFF;
}

.font-text-card-detail {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 14px;
    color: #202528;
}

.font-clear-condition-btn {
    font-size: 16px;
    color: #007BFF;
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
}

.re-native-link:hover {
    cursor: pointer;
    color: #0056B3;
}

.column-draft {
    min-width: 100px;
    padding-left: 10px;
}

.column-template {
    width: 100%;
    min-width: 237px;
    padding-left: 10px;
}

.column-customer-name {
    min-width: 142px;
    width: 238px;
    padding-left: 10px;
}

.column-updater {
    min-width: 142px;
    padding-left: 10px;
}

.column-service-at {
    min-width: 211px;
    padding-left: 10px;
}

.column-update-at {

    min-width: 211px;
    padding-left: 10px;
}

.max-width-table-record-beta {
    width: 100%;
    min-width: 1064px;
}

.header-table-record-beta {
    color: #757575;
    font-size: 14px;
}
.border-delete-btn {
    border: 1px solid red;
    height: 44px;
    border-radius: 5px;
    background-color: #ffffff;
    width: 88px;
}
.font-delete-label {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 16px;
    color: red;
}

.scroll-question-content {
    overflow: scroll;
    height: 300px;
}

.modal-select-record-beta > div > .modal-kiroku > .modal-dialog > .modal-content{
    margin-bottom: 32px;
}

.about-record-detail-beta {
    position: -webkit-sticky;
    position: sticky;
    z-index: 997;
}

.avatar-form-comment {
    float: left;
}

.input-form-comment {
    width: 100%;
    z-index: 2;
}
.form-comment {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background-color: white;
}

.border-read-record {
    border-left: 4px solid #2B91FF;
}
.link-action-modal-show-difference-question {
    cursor: pointer;
    color: #007bff;
}
.width-difference-question {
    width: 100%;
}

.font-text-in-batch-action {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
}

.font-text-card-btn {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
}

.font-text-record-card {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
}

